import { Agent, Person, Work } from "@biblioteksentralen/cordata";
import { ElasticsearchAgentResponse, ElasticsearchWorksResponse } from "@biblioteksentralen/search-models";
import { LanguageCode } from "@libry-content/localization";
import { Modify } from "@libry-content/types";
import { ResolvedEvent } from "../components/arrangement/sanityQuery";
import { ContributorRoleLabel } from "./cordata/roles";

export const searchCategories = ["works", "events", "agents"] as const;

export type SearchCategory = (typeof searchCategories)[number];

export const isWork = (item: unknown): item is Work => typeof item === "object" && (item as Work)?.["type"] === "Work";

// WorkType does not necessarily equal Work, depending on the `fields` search parameter
type WorksResponse<WorkType extends Partial<Work>> = Modify<ElasticsearchWorksResponse, { works: WorkType[] }>;

export const isWorksResponse = <WorkType extends Partial<Work>>(data: unknown): data is WorksResponse<WorkType> =>
  typeof data === "object" &&
  Array.isArray((data as ElasticsearchWorksResponse)?.["works"]) &&
  (data as ElasticsearchWorksResponse)?.["works"].every(isWork);

export const isAgent = (item: unknown): item is Agent =>
  typeof item === "object" &&
  ((item as Agent)?.["type"] === "Person" || (item as Agent)?.["type"] === "CollectiveAgent");

export const isPerson = (agent: Pick<Agent, "type">): agent is Person => agent?.type === "Person";

export const isAgentsResponse = (data: unknown): data is ElasticsearchAgentResponse =>
  typeof data === "object" &&
  Array.isArray((data as ElasticsearchAgentResponse)?.["agents"]) &&
  (data as ElasticsearchAgentResponse)?.["agents"].every(isAgent);

export type SanityEventsResponse = { events: ResolvedEvent[] };

type WorksResults = Partial<Modify<ElasticsearchWorksResponse, { works: Work[] }>>;

export type SearchResults = WorksResults &
  Partial<ElasticsearchAgentResponse> &
  SanityEventsResponse & {
    totals: Partial<Record<SearchCategory, number | undefined>>;
  };

export const emptySearchResults = searchCategories.reduce(
  (results, dataType) => ({
    ...results,
    [dataType]: undefined,
    totals: { ...results.totals, [dataType]: 0 },
  }),
  {} as SearchResults
);

type CommonSearchRequestSchema = { searchQuery?: string; size?: number; from?: number };

type isilCodesRequestData = { isilCodes: string[] | null };

export type SearchWorksRequestData = CommonSearchRequestSchema &
  isilCodesRequestData & {
    fields?: string[];
    contributorId?: string;
    personSubjectId?: string;
    subjectId?: string;
    holdingsIds?: string[];
  };

export type SearchEventsRequestData = CommonSearchRequestSchema & { siteId: string; languageCode: LanguageCode };

export type SearchAgentsRequestData = CommonSearchRequestSchema;

export type SearchWorksByContributorRoleRequestData = CommonSearchRequestSchema &
  isilCodesRequestData & {
    agentId: string;
    roleLabel: ContributorRoleLabel;
    fields?: string[];
  };
