import { Language as CordataLanguage, Expression, Work, isCordataLanguageCode } from "@biblioteksentralen/cordata";
import { LanguageCode } from "@libry-content/localization";
import { Modify } from "@libry-content/types";
import { chain, unique } from "radash";
import { listAsText } from "../../utils/listAsText";
import { sortAlphabetical } from "../../utils/sort";

export type CordataLanguageCode = CordataLanguage["code"];

export const toCordataLanguageCode = (languageCode: LanguageCode | undefined): CordataLanguageCode | undefined =>
  languageCode === "nb" ? "nob" : languageCode === "nn" ? "nno" : languageCode;

type LanguageNameWithSynonyms = CordataLanguage["name"] | "Bokmål" | "Nynorsk";

type LanguageWithSynonyms = Modify<CordataLanguage, { name: LanguageNameWithSynonyms }>;

const languageSynonyms: Partial<Record<CordataLanguage["name"], LanguageNameWithSynonyms>> = {
  "Norsk (bokmål)": "Bokmål",
  "Norsk (nynorsk)": "Nynorsk",
};

const applyLanguagenameSynonyms = (languages: CordataLanguage[]): LanguageWithSynonyms[] =>
  languages.map((language) =>
    typeof languageSynonyms[language.name] === "string"
      ? { ...language, name: languageSynonyms[language.name]! }
      : language
  );

/**
 * Default sorting of languages
 */
export const sortedCordataLanguageCodes: CordataLanguageCode[] = [
  "nno",
  "nob",
  "sme",
  "smj",
  "sma",
  "fkv",
  "swe",
  "fin",
  "dan",
  "ice",
  "eng",
];

export const getSortedLanguageCodeIndex = (
  language: Pick<CordataLanguage, "code"> | undefined,
  languageCodeOrder: readonly CordataLanguageCode[] = sortedCordataLanguageCodes
): number =>
  language?.code && languageCodeOrder.includes(language.code)
    ? languageCodeOrder.indexOf(language.code)
    : languageCodeOrder.length;

export type LanguagesSignature = string;

const languagesSignatureSeparator = "-";

export const getLanguagesSignature = (languages: CordataLanguage[]): LanguagesSignature =>
  sortAlphabetical(languages.map(({ code }) => code)).join(languagesSignatureSeparator);

export const languageListsEqual = (
  left: LanguagesSignature | CordataLanguage[] | undefined,
  right: LanguagesSignature | CordataLanguage[] | undefined
) =>
  (typeof left === "string" ? left : getLanguagesSignature(left ?? [])) ===
  (typeof right === "string" ? right : getLanguagesSignature(right ?? []));

export const formatLanguagesList = (languagesList: CordataLanguage[]) =>
  listAsText(handleLanguages(sortedCordataLanguageCodes)(languagesList).map(({ name }) => name));

const parseLanguagesSignature = (
  languageCodeOrSignature: LanguagesSignature | CordataLanguageCode
): CordataLanguageCode[] => languageCodeOrSignature.split(languagesSignatureSeparator).filter(isCordataLanguageCode);

const toArray = <T>(item: T | T[]) => (Array.isArray(item) ? item : [item]);

export const createLanguageCodeOrder = (
  preferredLanguageCodes: LanguagesSignature | CordataLanguageCode | CordataLanguageCode[] | undefined = []
) => unique([...toArray(preferredLanguageCodes), ...sortedCordataLanguageCodes].map(parseLanguagesSignature).flat());

const sortLanguages =
  (languageCodeOrder?: readonly CordataLanguageCode[]) =>
  (languages: CordataLanguage[]): CordataLanguage[] =>
    languages.sort(
      (languageA, languageB) =>
        getSortedLanguageCodeIndex(languageA, languageCodeOrder) -
        getSortedLanguageCodeIndex(languageB, languageCodeOrder)
    );

const uniqueLanguageCodes = (languages: CordataLanguage[]): CordataLanguage[] => unique(languages, ({ code }) => code);

export const handleLanguages = (
  languageCodeOrder?: readonly CordataLanguageCode[]
): ((languages: CordataLanguage[]) => CordataLanguage[]) =>
  chain(sortLanguages(languageCodeOrder), applyLanguagenameSynonyms, uniqueLanguageCodes);

type WorkWithLanguages = Modify<Work, { expressions: Pick<Expression, "languages">[] }>;

export const getExpressionLanguages = (expression: Pick<Expression, "languages">): CordataLanguage[] =>
  handleLanguages()(expression.languages);

const getWorkExpressionLanguages = (work: WorkWithLanguages): CordataLanguage[][] =>
  unique(
    work.expressions.map(({ languages }) => languages),
    getLanguagesSignature
  );

export const getAvailableWorkLanguages = (
  work: WorkWithLanguages,
  representativeLanguages?: LanguagesSignature
): CordataLanguage[][] => {
  const expressionLanguages = getWorkExpressionLanguages(work);
  const availableLanguages = expressionLanguages.length ? expressionLanguages : [work.languages];
  const languageCodeOrder = createLanguageCodeOrder(representativeLanguages);
  return availableLanguages.map(handleLanguages(languageCodeOrder));
};
