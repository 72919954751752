import { DocumentType, Expression, Holdings, Manifestation, Title, Work } from "@biblioteksentralen/cordata";
import { isNonNil } from "@libry-content/common";
import { min, unique } from "radash";
import { useRediaPlatformContext } from "../../rediaPlatform/RediaPlatformProvider";
import { sortByMultiple } from "../../utils/sort";
import { useLibrarySystemBranches } from "../hooks/useLibrarySystemBranches";
import { isValidCoverImage } from "./coverImage";
import { holdingCanBeReserved, holdingIsAvailable, isHoldingRepresentedInIsilCodes } from "./items";
import {
  CordataLanguageCode,
  createLanguageCodeOrder,
  getSortedLanguageCodeIndex,
  handleLanguages,
  languageListsEqual,
  sortedCordataLanguageCodes,
} from "./languages";
import { havePartTitles, haveSamePartTitlesSctructure, isbdFormattedTitle } from "./titles";

export const getDocumentTypes = ({ expressions }: Pick<Work, "expressions">): DocumentType[] =>
  unique(
    expressions.flatMap(({ manifestations }) =>
      manifestations.flatMap(({ documentType }) => documentType).filter(isNonNil)
    ),
    ({ format }) => format
  );

// TODO: Indicate these in dataplattform -> cordata types, similarly to languages?
const defaultFormatOrder = ["Bok", "E-bok", "Lydbok", "E-lydbok"];

export const getPreferredFormatOrderIndex = (
  format: string | undefined,
  preferredFormatOrder = defaultFormatOrder
): number =>
  format && defaultFormatOrder.includes(format) ? preferredFormatOrder.indexOf(format) : preferredFormatOrder.length;

type Representation = { representativeLanguages?: string; representativeFormat?: string };

export type WorkToBeRepresented = Work & Representation;

type ExpressionSummary<T extends Expression> = Pick<T, "languages" | "collections" | "contributors">;

export type SortableManifestation = Manifestation & { expression: ExpressionSummary<Expression> };

export const getSortableManifestation = (
  manifestation: Manifestation,
  expression: ExpressionSummary<Expression>,
  preferredLanguageCodeOrder?: readonly CordataLanguageCode[]
): SortableManifestation => ({
  ...manifestation,
  expression: {
    ...expression,
    languages: handleLanguages(preferredLanguageCodeOrder)(expression.languages),
  },
});

const getSortableManifestationsFromWork = (
  { expressions }: Work,
  preferredLanguageCodeOrder?: readonly CordataLanguageCode[]
): SortableManifestation[] =>
  unique(
    expressions.flatMap(({ manifestations = [], ...expression }) =>
      manifestations.map((manifestation) =>
        getSortableManifestation(manifestation, expression, preferredLanguageCodeOrder)
      )
    ),
    ({ id }) => id
  );

export type ManifestationSorter = (items: SortableManifestation[]) => SortableManifestation[];

const geSortedIsilCodeIndex = (preferredIsilCode: string | undefined, holdings: Holdings[]) =>
  preferredIsilCode ? min(holdings.map(({ isilCode }) => (isilCode === preferredIsilCode ? 1 : 2))) ?? 1 : 1;

export const sortManifestationsByRelevance =
  (
    workTitle: Title,
    preferredLanguageCodeOrder: readonly CordataLanguageCode[] = sortedCordataLanguageCodes,
    preferredFormatOrder: string[],
    preferredIsilCode?: string | undefined
  ): ManifestationSorter =>
  (items) =>
    sortByMultiple(
      items,
      ({ expression }) => getSortedLanguageCodeIndex(expression.languages[0], preferredLanguageCodeOrder),
      ({ documentType }) => getPreferredFormatOrderIndex(documentType?.format, preferredFormatOrder),
      ({ title }) => (haveSamePartTitlesSctructure(workTitle, title) ? 1 : 2),
      ({ holdings = [] }) => (holdings.some(holdingCanBeReserved) ? 1 : 2),
      ({ holdings = [] }) => geSortedIsilCodeIndex(preferredIsilCode, holdings),
      ({ holdings = [] }) => (holdings.some(holdingIsAvailable) ? 1 : 2),
      ({ publicationYear }) => (isNaN(Number(publicationYear)) ? 0 : -Number(publicationYear)),
      ({ coverImage }) => (isValidCoverImage(coverImage) ? 1 : 2)
    );

/**
 * Preferred in order: language then published date.
 * Choose an alternative cover image if necessary, in the same language
 *
 * TODO: Think about parts, series...
 * TODO: Unit test
 */
export const useRepresentativeManifestation = ({
  representativeLanguages,
  representativeFormat,
  ...work
}: WorkToBeRepresented): SortableManifestation | undefined => {
  const { user } = useRediaPlatformContext();
  const { isilCodeFromBranchCode } = useLibrarySystemBranches();

  const preferredIsilCode = isilCodeFromBranchCode && user ? isilCodeFromBranchCode(user.pickupBranchCode) : undefined;
  const languageCodeOrder = createLanguageCodeOrder(representativeLanguages);
  const formatOrder = representativeFormat ? unique([representativeFormat, ...defaultFormatOrder]) : defaultFormatOrder;

  const sortManifestations = sortManifestationsByRelevance(
    work.title,
    languageCodeOrder,
    formatOrder,
    preferredIsilCode
  );

  const sortedManifestations = sortManifestations(getSortableManifestationsFromWork(work, languageCodeOrder));
  const representativeManifestation = sortedManifestations?.[0];

  if (!representativeManifestation) return undefined;

  // TODO: Handle several languages. URL parameters with several?
  const representativeLanguage = representativeManifestation.expression.languages[0]?.code;

  const mostRelevantCoverImage = sortedManifestations
    .filter(({ expression }) => !!expression.languages[0] && expression.languages[0].code === representativeLanguage)
    // TODO: Also force same format?
    .find(({ coverImage }) => isValidCoverImage(coverImage))?.coverImage;

  return { ...representativeManifestation, coverImage: mostRelevantCoverImage };
};

export const filterManifestationsOnHoldings = <ManifestationType extends Partial<Manifestation>>(
  manifestations: ManifestationType[],
  isilCodes: string[] | null
): ManifestationType[] =>
  manifestations
    .filter(({ holdings = [] }) => !!holdings?.some(isHoldingRepresentedInIsilCodes(isilCodes)))
    .map((manifestation) => ({
      ...manifestation,
      holdings: manifestation.holdings?.filter(isHoldingRepresentedInIsilCodes(isilCodes)),
    }));

export const getFormattedRepresentativeManifestationTitle = (
  work: Work,
  representativeManifestation: Manifestation | undefined
) => {
  const title = representativeManifestation?.title ?? work.title;
  return isbdFormattedTitle(title, havePartTitles(work.title));
};

export const getRelevantManifestations = (work: WorkToBeRepresented) => {
  const manifestationsWithLanguages = getSortableManifestationsFromWork(work);

  return manifestationsWithLanguages.filter(
    ({ expression, documentType }) =>
      languageListsEqual(expression.languages, work.representativeLanguages) &&
      documentType?.format === work.representativeFormat
  );
};

export const getDefaultWorkImage = (work: Work) =>
  sortManifestationsByRelevance(work.title, [], [])(getSortableManifestationsFromWork(work))?.[0]?.coverImage;
