import { Manifestation, Work } from "@biblioteksentralen/cordata";
import { filterManifestationsOnHoldings } from "./manifestations";

const isNotEbook = ({ documentType }: Pick<Manifestation, "documentType">) =>
  documentType?.format?.toLowerCase().replace("-", "") !== "ebook";

/**
 * Filter out:
 * - Manifestations and expressions that are not in the library's collection
 * - Ebook-manifestations and expressions (temporary solution; can lead to empty works in the frontend)
 */
export const filterWorkData =
  <WorkType extends Partial<Work>>(isilCodes: string[] | null) =>
  (work: WorkType): WorkType => {
    if (!isilCodes) return work;

    const expressions = work.expressions
      ?.map(({ manifestations = [], ...expression }) => ({
        ...expression,
        manifestations: filterManifestationsOnHoldings(manifestations, isilCodes).filter(isNotEbook),
      }))
      .filter(({ manifestations = [] }) => manifestations.length > 0);

    return { ...work, expressions };
  };
