import { filters, getNorwegianDateNow } from "@libry-content/common";
import { LanguageCode } from "@libry-content/localization";
import { groq } from "next-sanity";
import {
  groqEventStart,
  groqStartDate,
  ResolvedEvent,
  resolveEventGroqProjection,
} from "../components/arrangement/sanityQuery";
import { sanityClient } from "../utils/sanity/client";
import { defaultSizes } from "./searchApi";
import { SearchEventsRequestData } from "./types";

const groqSearchMatch = groq`"*" + $searchQuery + "*"`;

const relevantEventsGroqQuery = (languageCode: LanguageCode) => groq`
  *[
    _type == "event" &&
    site._ref == $siteId &&
    (eventStatus == "available" || eventStatus == "full")
    && ${filters.eventTodayOrLater}
  ] |
  score(
    boost(title.${languageCode} match ${groqSearchMatch}, 3),
    boost(eventType.${languageCode} match ${groqSearchMatch}, 1),
    // TODO: related literature, titles and authors?
  )
  [_score > 0]
`;

const resolvedEventsGroqQuery = (languageCode: LanguageCode, size?: number, from = 0) => {
  const slice = size ? `[${from}..${size}]` : "";

  return groq`
    ${relevantEventsGroqQuery(languageCode)} |
    order(${groqStartDate} asc, ${groqEventStart} asc)
    ${slice}
    {${resolveEventGroqProjection}}`;
};

type SearchEventsResponse = { events: ResolvedEvent[]; total: number };

const eventsGroqQuery = (languageCode: LanguageCode, size: number, from: number) => groq`{
  "events": ${resolvedEventsGroqQuery(languageCode, size, from)},
  "total": count(${relevantEventsGroqQuery(languageCode)})
}`;

export const searchEvents = async (data: SearchEventsRequestData) => {
  const { searchQuery, siteId, size = defaultSizes.events, languageCode, from = 0 } = data;
  if (!searchQuery) return { events: [], total: 0 };

  const params = { today: getNorwegianDateNow(), siteId, searchQuery };
  return await sanityClient.fetch<SearchEventsResponse>(eventsGroqQuery(languageCode, size, from), params);
};

export const searchFilterIsilCodesQuery = groq`librarySystem.searchFilterIsilCodes`;
