import { NextRouter, useRouter } from "next/router";
import { ParsedUrlQuery } from "querystring";
import { isEqual, omit } from "radash";
import { useCallback } from "react";
import { useCurrentCanonicalPath } from "./useCurrentCanonicalPath";

const getParameterString = (router: NextRouter) => (parameterName: string) => {
  const parameter = router.query?.[parameterName];
  return typeof parameter === "string" ? decodeURIComponent(parameter) : undefined;
};

export type ParameterUpdates = { key: string; value: string | number | undefined }[];

const updateUrlQuery = (currentQuery: ParsedUrlQuery, parameterUpdates: ParameterUpdates) => {
  const updatedQuery = parameterUpdates.reduce(
    (acc, { key, value }) => (value ? { ...acc, [key]: encodeURIComponent(value) } : omit(acc, [key])),
    currentQuery as Record<string, string>
  );

  // The "site" parameter, used in middleware for rewrites, is a special
  // case and should not be included with the URL parameters
  return omit(updatedQuery, ["site"]);
};

/**
 * Use URL query parameters as a single source of truth
 */
export const useUrlQueryParameters = <ParameterNames extends string>(parameterNames: ParameterNames[]) => {
  const router = useRouter();
  const currentPath = useCurrentCanonicalPath();

  const getUpdatedPath = useCallback(
    (parameterUpdates: ParameterUpdates) => {
      const updatedQuery = updateUrlQuery(router.query, parameterUpdates);

      if (isEqual(router.query, updatedQuery)) return;

      return { pathname: currentPath, query: updatedQuery };
    },
    [currentPath, router]
  );

  const updateParameters = useCallback(
    (parameterUpdates: ParameterUpdates, { replace } = { replace: false }) => {
      const updatedParameters = getUpdatedPath(parameterUpdates);

      if (!updatedParameters) return;

      if (replace) return router.replace(updatedParameters, undefined, { scroll: false });
      return router.push(updatedParameters, undefined, { scroll: false });
    },
    [getUpdatedPath, router]
  );

  const parameterValues = parameterNames.map(getParameterString(router));

  return { parameterValues, updateParameters, getUpdatedPath };
};
