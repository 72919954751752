import { Manifestation, Title, Work } from "@biblioteksentralen/cordata";
import { SortableManifestation } from "./manifestations";
import { sift } from "radash";

export const isbdFormattedTitle = (title: Title | undefined, includePartTitles = true) => {
  if (!title) return undefined;

  const { mainTitle, subtitle, partNumber, partTitle } = title;
  const subtitleSegment = subtitle ? ` : ${subtitle}` : "";
  const titleWithSubtitle = `${mainTitle}${subtitleSegment}`;

  if (!includePartTitles) return titleWithSubtitle;

  const partNumberSegment = partNumber ? `. ${partNumber}` : "";
  const partTitleSegment = partTitle ? `. ${partTitle}` : "";

  return `${titleWithSubtitle}${partNumberSegment}${partTitleSegment}`;
};

export const havePartTitles = ({ partTitle, partNumber }: Title) => !!partTitle || !!partNumber;

export const haveSamePartTitlesSctructure = (leftTitle: Title, rightTitle: Title) =>
  havePartTitles(leftTitle) === havePartTitles(rightTitle);

export const getOriginalLanguageTitle = (
  { title: workTitle }: Work,
  { expression }: Partial<SortableManifestation> = {}
) =>
  !!workTitle.titleLanguage &&
  !expression?.languages?.some(({ code }) => !!code && code === workTitle.titleLanguage?.code)
    ? isbdFormattedTitle(workTitle, true)
    : undefined;

export const getParallelTitles = (manifestation: Pick<Manifestation, "parallelTitles"> | undefined) =>
  sift(manifestation?.parallelTitles.map((title) => isbdFormattedTitle(title)) ?? []);
