import { useCommonData } from "../../components/layout/CommonDataProvider";
import { useFeatureToggle } from "../../utils/hooks/featureToggles";
import { useIsOnCollectionPage } from "./useIsOnCollectionPage";
import { useIsOnSearchPage } from "./useIsOnSearchPage";

export const useNewSearch = () => {
  const isOnSearchPage = useIsOnSearchPage();
  const isOnCollectionPage = useIsOnCollectionPage();
  const { site } = useCommonData();
  const searchFeatureToggleIsOn = useFeatureToggle("search");

  if (site?.librarySystem?.type !== "bibliofil" || !site.librarySystem.searchFilterIsilCodes?.length) return false;

  return searchFeatureToggleIsOn || isOnSearchPage || isOnCollectionPage;
};
