import {
  createRediaPlatformClient,
  createRediaPlatformMockClient,
  createSessionStore,
  Patron,
  RediaPlatform,
} from "@libry-content/redia-platform";
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { useToggle } from "react-use";
import { useCommonData } from "../components/layout/CommonDataProvider";
import { useClientSideReady } from "../utils/hooks/useClientSideReady";

export interface RediaPlatformContextType {
  isRediaPlatformConfigured: boolean;
  isSessionReady: boolean;
  rediaPlatform: RediaPlatform | undefined;
  user: Patron | undefined;
  toggleUseMockClient: () => void;
}

const initialState: RediaPlatformContextType = {
  rediaPlatform: undefined,
  user: undefined,
  isSessionReady: false,
  isRediaPlatformConfigured: false,
  toggleUseMockClient: () => null,
};

export const RediaPlatformContext = createContext(initialState);

export const useRediaPlatformContext = () => useContext(RediaPlatformContext);

const sessionStorageKey = "redia-platform-session";

export function RediaPlatformProvider({ children }: { children: ReactNode }) {
  const [useMockClient, toggleUseMockClient] = useToggle(false);
  const customerId = useCommonData()?.site?.librarySystem?.rediaCustomerId;
  const clientSideReady = useClientSideReady();
  const [user, setUser] = useState<Patron | undefined>(undefined);
  const [isSessionReady, setIsSessionReady] = useState<boolean>(false);
  const isRediaPlatformConfigured = typeof customerId !== "undefined";

  const rediaPlatform = useMemo(() => {
    if (!clientSideReady || typeof customerId === "undefined") return undefined;

    const sessionStore = createSessionStore({
      storage: localStorage,
      key: sessionStorageKey,
      onChange: (session) => {
        // Oppdaterer React state når bruker har logget inn/ut
        console.debug("User state changed", session?.user);
        setUser(session?.user);
        setIsSessionReady(true);
      },
    });

    const createClient = useMockClient ? createRediaPlatformMockClient : createRediaPlatformClient;
    return createClient({
      customerId,
      sessionStore,
    });
  }, [clientSideReady, customerId, useMockClient]);

  useEffect(() => {
    const onStorageEvent = (event: StorageEvent) => {
      if (event.key === sessionStorageKey) {
        // Oppdaterer React state når bruker har logget inn/ut i annet vindu/annen fane.
        setUser(rediaPlatform?.getUser());
      }
    };
    window.addEventListener("storage", onStorageEvent);
    return () => {
      window.removeEventListener("storage", onStorageEvent);
    };
  }, [rediaPlatform]);

  return (
    <RediaPlatformContext.Provider
      value={{
        rediaPlatform,
        user,
        isSessionReady,
        isRediaPlatformConfigured,
        toggleUseMockClient,
      }}
    >
      {children}
    </RediaPlatformContext.Provider>
  );
}
